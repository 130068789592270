import {FC} from "react";
import {Grid, Typography} from "@mui/material";
import {palette} from "../../../constants";
import {IPortfolioAttemptStatus} from "../../../types/portfolio/IPortfolioAttempt";

interface IProps {
	status: IPortfolioAttemptStatus
}

export const PortfolioReviewStatusBanner: FC<IProps> = ({status}) => {
	const config: { [key in IPortfolioAttemptStatus]: { title: string, text: string, image: string } } = {
		"awaiting-review": {
			title: "Awaiting Review",
			text: "This Portfolio has been submitted by the learner requires a review before the learner can complete the module.",
			image: "/portfolio-awaiting-review.png"
		},
		"approved": {
			title: "Portfolio Approved",
			text: "This portfolio has been marked as approved, completing the module for the learner and allowing them to progress",
			image: "/portfolio-approved.png"
		},
		"rejected": {
			title: "Portfolio Rejected",
			text: "This portfolio has been marked as rejected and pushed back to the learner for amendments. Please check the message log for details.",
			image: "/portfolio-rejected.png"
		}
	}
	return (
		<Grid container spacing={2} sx={{px: {md: 8, xs: 2}, py: {md: 4, xs: 2}, color: palette.darkBlue, bgcolor: palette.pastelBlue, mt: 1, borderRadius: "10px"}}>
			<Grid item xs={12} md={3}>
				<img alt="Portfolio Status" src={config[status].image} />
			</Grid>
			<Grid item xs={12} md={9}>
				<Typography variant="h4" sx={{mb: 1}}>{config[status].title}</Typography>
				<Typography>{config[status].text}</Typography>
			</Grid>
		</Grid>
	)
}