import {FC, useContext, useEffect, useRef, useState} from "react"
import {Box, Button, Dialog, DialogContent, DialogTitle, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {InputText} from "@plumeuk/shapeshift-common/inputText";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {AuthContext, useApi} from "@plumeuk/shapeshift-identity";
import SignaturePad from "react-signature-canvas";
import {IPortfolioQuestion} from "../../../../types/portfolio/IPortfolio";
import {IPortfolioAttempt} from "../../../../types/portfolio/IPortfolioAttempt";
import {IPortfolioAnswerAudit} from "../../../../types/portfolio/IPortfolioAnswerAuditRequest";

interface IProps {
	onClose: () => void,
	onSave: (newAudit: IPortfolioAnswerAudit, fileId: number) => void,
	open?: boolean,
	file?: {id?: number, name?: string},
	auditId?: number,
	question: IPortfolioQuestion,
	attempt?: IPortfolioAttempt
}

const useStyles = makeStyles()((theme) => ({
	portfolioQuestionFileAudit: {

	},
	sigCanvas: {
		width: "445px",
		"& .signatureCanvas": {
			borderBottom: "2px solid " + theme.palette.common.black
		}
	},
	feedbackActionContainer: {
		marginTop: "20px",
		display: "flex",
		justifyContent: "space-between",
		"& button": {
			minWidth: "200px",
			display: "flex",
			gap: "5px",
			"&:nth-of-type(1) svg": {
				color: theme.palette.error.main
			},
			"&:nth-of-type(2) svg": {
				color: theme.palette.success.main
			}
		}
	},
	feedbackResultContainer: {
		display: "flex",
		marginTop: "20px",
		justifyContent: "center",
		padding: "10px",
		background: theme.palette.background.paper,
		borderRadius: "5px",
		border: theme.palette.grey[300] + " 1px solid",
		"> div": {
			display: "flex",
			gap: "5px",
			"&.approved svg": {
				color: theme.palette.success.main
			},
			"&.rejected svg": {
				color: theme.palette.error.main
			}
		}
	},
	signitureRender: {
		borderRadius: "5px",
		margin: "10px 0",
		border: theme.palette.grey[300] + " 1px solid"
	}
}));

export const PortfolioQuestionFileAuditDialog: FC<IProps> = ({onClose, open, file, attempt, question, onSave, auditId}) => {
	const {classes} = useStyles();
	const {user} = useContext(AuthContext)
	const sigCanvas = useRef<any>({});
	const [feedback, setFeedback] = useState("");
	const [postAuditResponse, postAudit] = useApi<IPortfolioAnswerAudit, FormData>();
	const [getAuditResponse, getAudit] = useApi<IPortfolioAnswerAudit>();
	const [audit, setAudit] = useState<IPortfolioAnswerAudit>();

	useEffect(() => {
		if(auditId){
			getAudit("/api/portfolioAnswerAudit/"+auditId)
			setFeedback("")
		}
	}, [auditId])

	useEffect(() => {
		if(getAuditResponse.data)
			setAudit(getAuditResponse.data)
	}, [getAuditResponse])

	useEffect(() => {
		if(postAuditResponse.data && file?.id){
			onSave(postAuditResponse.data, file.id)
		}
	}, [postAuditResponse])

	const save = (approve?: boolean): void => {
		const imgUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
		const imgFile = base64ToFile(imgUrl, question.id + "_" + (file?.name ?? "")+ "_file_audit_" + user?.email + "_sig.png", "image/png")
		if(!file?.id || !attempt)
			return;

		const payloadFormData = new FormData();
		payloadFormData.append("comment", feedback);
		payloadFormData.append("questionId", question.id.toString());
		payloadFormData.append("questionType", question.type);
		payloadFormData.append("attemptId", attempt.id.toString());
		payloadFormData.append("approve", approve ? "true" : "false");
		payloadFormData.append("fileId", (file?.id)?.toString());
		payloadFormData.append("signiture", imgFile);

		postAudit({
			method: "POST",
			url: "/api/portfolioAnswerAudit",
			data: payloadFormData,
			config: {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			}
		})
	}

	const clear = (): void => sigCanvas.current.clear();

	return (<>
		<Dialog className={classes.portfolioQuestionFileAudit} onClose={() => onClose()} open={!!open}>
			<DialogTitle>Audit</DialogTitle>
			<DialogContent>
				{auditId === undefined ? <InputText value={feedback} onChange={e => setFeedback(e.target.value)} label={"Feedback"}/> : <Typography>Feedback: {audit?.comment ?? "none"}</Typography>}

				<Typography>Please note this approval is final and cannot be revoked</Typography>
				<br />
				{(auditId === undefined) && <Typography>Auditor: {user?.firstname} {user?.lastname}</Typography>}
				{file && <Typography>File: {file?.name}</Typography>}

				{(auditId === undefined) && <Box className={classes.sigCanvas}>
					<SignaturePad
						ref={sigCanvas}
						canvasProps={{
							width: 445,
							className: "signatureCanvas"
						}}
					/>
					<Box sx={{display: "flex", justifyContent: "space-between"}}>
						<Typography sx={{fontSize: "12px"}}>Sign here</Typography>
						<Typography sx={{fontSize: "12px", cursor: "pointer"}} onClick={() => clear()}>Clear</Typography>
					</Box>
				</Box>}
				{(audit && audit.signiture) && <>
					<img className={classes.signitureRender} src={audit.signiture.url} />
					<Typography sx={{fontSize: "12px"}}>Signed by {audit.auditor?.firstname} {audit.auditor?.lastname}</Typography>
				</>}


				{(auditId === undefined) && <Box className={classes.feedbackActionContainer}>
					<Button color="error" onClick={() => save(false)}>
						<CloseIcon />
						<Typography>Reject</Typography>
					</Button>
					<Button onClick={() => save(true)}>
						<DoneIcon />
						<Typography>Approve</Typography>
					</Button>
				</Box>}

				{(audit && audit.auditedAt) && <Box className={classes.feedbackResultContainer}>
					{audit.approved && <Box className="approved">
						<DoneIcon />
						<Typography>Approved: {new Date(audit.auditedAt).toLocaleDateString()}</Typography>
					</Box>}
					{!audit.approved && <Box className="rejected" color="error" >
						<CloseIcon />
						<Typography>Rejected: {new Date(audit.auditedAt).toLocaleDateString()}</Typography>
					</Box>}
				</Box>}
			</DialogContent>
		</Dialog>
	</>
	)
}


function base64ToFile(base64String: string, fileName: string, mimeType: string): File {
	// Remove the base64 header if it's included
	const byteString = atob(base64String.split(",")[1]);
	const arrayBuffer = new ArrayBuffer(byteString.length);
	const uintArray = new Uint8Array(arrayBuffer);

	for (let i = 0; i < byteString.length; i++) {
		uintArray[i] = byteString.charCodeAt(i);
	}

	// Create a blob with the array buffer and MIME type
	const blob = new Blob([uintArray], {type: mimeType});

	// Optionally create a File object if needed
	const file = new File([blob], fileName, {type: mimeType});

	return file;
}