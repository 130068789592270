import {Autocomplete, TextField} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {useEffect, useState} from "react";
import {IUserEntity} from "@plumeuk/shapeshift-types";

interface IProps {
	learnerId?: number,
	onChange: (e?: number) => void
}

const useStyles = makeStyles()((theme) => ({
	learnerAutoCompleteInput: {
		width: 250
	},
	learnerInput: {
		padding: 0
	}
}));

export const LearnerAutoCompleteInput: React.FC<IProps> = ({learnerId, onChange}) => {
	const {classes} = useStyles();
	const [learnerSearch, setLearnerSearch] = useState<string>("");
	const [learners, searchLearners] = useApi<IUserEntity[]>();

	useEffect(() => {
		searchLearners({url: "/api/user/searchLearners", data: {search: learnerSearch}})
	}, [learnerSearch])

	const options = (learners?.data ?? []).map(e => ({label: e.email, id: e.id}));

	return (

		<Autocomplete<{ id: number, label: string }>
			value={options.find(e => e.id === learnerId)}
			getOptionLabel={e => {
				return e.label;
			}}
			isOptionEqualToValue={(e, v) => e?.id === v?.id}
			onChange={(_event, newValue) => {
				onChange(newValue?.id ?? undefined);
			}}
			inputValue={learnerSearch ?? ""}
			onInputChange={(_event, newInputValue) => {
				setLearnerSearch(newInputValue);
			}}
			id="learner-search"
			options={options}
			className={classes.learnerAutoCompleteInput}
			renderInput={(params) => <TextField {...params} className={classes.learnerInput} label="Learner" />}
		/>

	);
}