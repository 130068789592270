import {FC} from "react";
import {Grid, Typography} from "@mui/material";
import {palette} from "../../../constants";
import {IPortfolioAttemptStatus} from "../../../types/portfolio/IPortfolioAttempt";

interface IProps {
	status: IPortfolioAttemptStatus
}

export const PortfolioStatusBanner: FC<IProps> = ({status}) => {
	const config: { [key in IPortfolioAttemptStatus]: { title: string, text: string, image: string } } = {
		"awaiting-review": {
			title: "Your uploads are now under review by our assessors",
			text: "We'll let you know when your assessment feedback is available. Once you've reviewed your feedback, you can complete the course and claim your certificate.",
			image: "/portfolio-awaiting-review.png"
		},
		"approved": {
			title: "Portfolio Evaluation Complete",
			text: "Congratulations on completing the assessment! You're one step closer to finishing the course. Your feedback will be available soon.",
			image: "/portfolio-approved.png"
		},
		"rejected": {
			title: "Review Needed",
			text: "Your assessment has been reviewed, and we've provided some feedback to help you improve. Please review the comments and make any necessary changes before resubmitting.",
			image: "/portfolio-rejected.png"
		}
	}
	return (
		<Grid container spacing={2} sx={{px: {md: 8, xs: 2}, py: {md: 4, xs: 2}, color: palette.darkBlue, bgcolor: palette.pastelBlue, mt: 1, borderRadius: "10px"}}>
			<Grid item xs={12} md={3}>
				<img alt="Portfolio Status" src={config[status].image} />
			</Grid>
			<Grid item xs={12} md={9}>
				<Typography variant="h4" sx={{mb: 1}}>{config[status].title}</Typography>
				<Typography>{config[status].text}</Typography>
			</Grid>
		</Grid>
	)
}