import {FC, useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {makeStyles} from "tss-react/mui";
import {NotesSection} from "@plumeuk/shapeshift-common/notes";
import {Files} from "@plumeuk/shapeshift-common/files";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {ModuleCompleteButton, ModuleUnCompleteButton, ModuleTabs, ModuleTab} from "@plumeuk/shapeshift-common/module";
import {IModuleEnrollment} from "@plumeuk/shapeshift-common/types";
import {INotificationSeverity, APIState, NotificationContext} from "@plumeuk/shapeshift-identity";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {CurriculumContext} from "../../../contexts/curriculumContext";
import {LessonVideoPlayer} from "./LessonVideoPlayer";
import {MiniQuizzer} from "./MiniQuizzer";
import {ILesson} from "@plumeuk/shapeshift-types";
import {VideoPlayerRef} from "@plumeuk/shapeshift-common/videoPlayer/videoPlayer";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";

const useStyles = makeStyles()((theme) => ({
	title: {
		marginBottom: "40px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	centeredContainer: {
		paddingBottom: "100px",
		maxWidth: "900px"
	},
	lessonCompleteBtn: {
		float: "right"
	},
	content: {
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px"
		}
	},
	contentImage: {
		maxWidth: "100%"
	},
	fileTitle: {
		fontWeight: 700,
		margin: "20px 0"
	},
	files: {
		marginBottom: "40px"
	},
	miniQuizzer: {
		marginBottom: "80px",
		minHeight: "550px"
	},
	nextBtn: {
		position: "absolute",
		[theme.breakpoints.down("sm")]: {
			margin: 0,
			borderRadius: "0",
			left: 0,
			top: "240px",
			padding: "14px 0",
			right: "0px",
			"& svg": {
				fontSize: "20px"
			}
		},
		[theme.breakpoints.up("sm")]: {
			zIndex: 10,
			background: "#fff",
			color: theme.palette.secondary.main,
			right: "-160px",
			fontSize: "22px",
			opacity: 0.7,
			top: "200px",
			borderRadius: 100,
			padding: "22px 150px 22px 22px",
			transition: "all 0.2s ease-in-out",
			"&:hover": {
				opacity: 1,
				right: "-140px",
				color: theme.palette.secondary.main,
				background: "#fff"
			},
			"& span": {
				display: "none"
			},
			"& svg": {
				fontSize: "48px"
			}
		}
	},
	tabs: {
		marginBottom: "50px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	activeTab: {
		color: "#2A2A2F !important"
	}
}));


interface IProps {
	lesson?: ILesson,
	lessonSlug?: string,
	courseSlug?: string
}

export const LessonPageContent: FC<IProps> = ({lesson}) => {
	const {classes} = useStyles();
	const {courseSlug} = useParams();
	const {moduleSlug: lessonSlug} = useParams();
	const {curriculumDispatch} = useContext(CurriculumContext);
	const {notify} = useContext(NotificationContext);
	const navigate = useNavigate();
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const [passedQuiz, setPassedQuiz] = useState<boolean>(false);
	const videoRef = useRef<VideoPlayerRef>(null);

	useEffect(() => {
		if (lesson && (!(lesson as any).quiz || (lesson as any).quiz.scoreRequired === 0)) {
			setPassedQuiz(true);
		}
	}, [lesson])

	const handleCompletion = useCallback((e: APIState<IModuleEnrollment>): void => {
		if (e.isError) {
			notify("Please try again", "Something went wrong", INotificationSeverity.error, 5000);
		}
		else if (!e.isLoading && !e.isError && e.statusCode === 200) {
			const status = !!e?.data?.complete;
			curriculumDispatch({
				type: "setModuleComplete", module: {type: "lesson", slug: lessonSlug as string}, status
			})

			if (e?.data?.complete) {
				notify(null, "Lesson completed", INotificationSeverity.success, 5000);
				navigate("/course/" + courseSlug)
			}
			else {
				notify("Set to incomplete", "Lesson Updated", INotificationSeverity.success, 5000);
			}
		}
	}, [curriculumDispatch, lessonSlug])

	return (
		<Box>
			{lesson?.videoUrl && <>
				<LessonVideoPlayer ref={videoRef} lesson={lesson} />
				{courseSlug && <ModuleCompleteButton
					courseSlug={courseSlug}
					disabled={!passedQuiz}
					moduleSlug={lesson.slug}
					type="lesson"
					className={classes.nextBtn}
					onApiUpdate={handleCompletion}
				>
					{() => <><span>Next Lesson</span> <NavigateNextIcon /></>}
				</ModuleCompleteButton>}

			</>}
			<CenteredContainer className={classes.centeredContainer}>
				<ModuleTabs tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} moduleTabs={[{label: "Overview", value: "overview"}, {label: "Notes", value: "notes"}]}/>
				{tabSelected === "overview" && <>
					<PageTitle className={classes.title} title={lesson?.title} subtitle={lesson?.subtitle} />
					<WYSIWYG className={classes.content}>
						{lesson?.content ?? ""}
					</WYSIWYG>

					{(lesson as any)?.quiz &&
						<MiniQuizzer
							autoStart
							className={classes.miniQuizzer}
							onComplete={result => setPassedQuiz(result.pass)}
							quiz={(lesson as any).quiz}
						/>
					}

					{lesson?.files && <>
						<Typography className={classes.fileTitle} variant="h5">Files</Typography>
						<Files className={classes.files} files={lesson.files} />
					</>}
					{courseSlug && lesson?.slug && !lesson?.complete &&
						<ModuleCompleteButton
							disabled={!passedQuiz}
							courseSlug={courseSlug}
							moduleSlug={lesson.slug}
							type="lesson"
							className={classes.lessonCompleteBtn}
							onApiUpdate={handleCompletion}
						/>
					}
					{courseSlug && lesson?.slug && lesson?.complete &&
						<ModuleUnCompleteButton
							courseSlug={courseSlug}
							moduleSlug={lesson.slug}
							type="lesson"
							className={classes.lessonCompleteBtn}
							onApiUpdate={handleCompletion}
						/>
					}
				</>}
				{/* {(tabSelected === "comments" && lesson?.progression !== false) && <CommentsSection courseSlug={courseSlug} moduleType={"lesson"} moduleSlug={lessonSlug}/>} */}
				{tabSelected === "notes" && <NotesSection courseSlug={courseSlug} moduleType="lesson" moduleSlug={lessonSlug} videoRef={videoRef}/>}
			</CenteredContainer>

		</Box>
	);
}