import {Box, Button, MenuItem, Select, Typography} from "@mui/material";
import {PageTitle} from "@plumeuk/shapeshift-common/pageBase";
import {useParams} from "react-router-dom";
import {ModuleTab, ModuleTabs} from "@plumeuk/shapeshift-common/module";
import {CenteredContainer} from "../../../components/pageBase/centeredContainer";
import {getAllFontStyles} from "../../../templates/defaultTheme";
import {makeStyles} from "tss-react/mui";
import {useContext, useEffect, useState} from "react";
import {WYSIWYG} from "@plumeuk/shapeshift-common/wysiwyg";
import {CommentsSection} from "@plumeuk/shapeshift-common/comments";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {IUserSlim} from "@plumeuk/shapeshift-types/IUser";
import {PortfolioReviewStatusBanner} from "./PortfolioReviewStatusBanner";
import {IPortfolioAdminReviewEntry} from "../../../types/portfolio/IPortfolioAdminEntry";
import {PortfolioQuestions} from "../../ModulePage/PortfolioPage/PortfolioQuestions/PortfolioQuestions";
import {useSetPortfolioAdminReview} from "../../../hooks/useSetPortfolioAdminReview";
import {IPortfolioAttempt} from "../../../types/portfolio/IPortfolioAttempt";
import {AuthContext} from "@plumeuk/shapeshift-identity";

const useStyles = makeStyles()((theme) => ({
	portfolioPage: {
		paddingTop: "50px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "20px"
		}
	},
	centeredContainer: {
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
		gap: "30px",
		paddingBottom: "100px",
		maxWidth: "900px"
	},
	title: {
		marginBottom: "10px",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		}
	},
	tabs: {
		marginBottom: "20px"
	},
	tab: {
		color: "#A8A8A8",
		fontSize: "18px"
	},
	content: {
		...getAllFontStyles(theme),
		marginBottom: "40px",
		"& p": {
			lineHeight: 1.5,
			fontSize: "18px"
		}
	},
	activeTab: {
		color: "#2A2A2F !important"
	},
	expiredSubmissionInfoContainer: {
		margin: "30px 0",
		display: "flex",
		gap: "15px",
		flexDirection: "column",
		justifyContent: "center",
		"& button": {
			minWidth: "300px",
			width: "300px"
		}
	},
	feedbackActionContainer: {
		display: "flex",
		marginTop: "20px",
		gap: "20px",
		"& button": {
			minWidth: "200px",
			display: "flex",
			gap: "5px",
			"&:nth-of-type(1) svg": {
				color: theme.palette.error.main
			},
			"&:nth-of-type(2) svg": {
				color: theme.palette.success.main
			}
		}

	}
}));

interface IProps {
	reviewEntry: IPortfolioAdminReviewEntry,
	onChange: (e: IPortfolioAttempt) => void
}

export const PortfolioReviewPageContent: React.FC<IProps> = ({reviewEntry, onChange}) => {
	const {classes} = useStyles();
	const {courseSlug, portfolioSlug, learnerId} = useParams();
	const [submission, setSubmission] = useState<number>(-1);
	const [tabSelected, setTab] = useState<ModuleTab>("overview");
	const selectedSubmission = reviewEntry.submissions.find(e => e.id === submission);
	const lastAttempt = reviewEntry.submissions?.[reviewEntry.submissions.length - 1];
	const expiredSelected = lastAttempt.id !== submission;
	const {response, setReview} = useSetPortfolioAdminReview();
	const {user} = useContext(AuthContext)

	useEffect(() => {
		if(reviewEntry.submissions.length > 0)
			setSubmission(reviewEntry.submissions[reviewEntry.submissions.length - 1].id)
	}, [reviewEntry.submissions])

	const getName = (user: IUserSlim | undefined): string => {
		if(!user)
			return "";
		let result = user.firstname ?? "";
		result += (result?.length > 0 ? " " : "") + user.lastname;
		if(result?.length <= 1)
			return user.email ?? "";
		return result;
	}

	useEffect(() => {
		if(response){
			onChange(response)
		}
	}, [response])

	return (

		<CenteredContainer className={classes.centeredContainer}>
			<PageTitle className={classes.title} title={reviewEntry?.portfolio?.title} subtitle={reviewEntry?.portfolio?.subtitle} />

			<Box>
				<Typography>Learner: {getName(reviewEntry?.learner)}</Typography>
				<Typography>Assessor: {getName(reviewEntry?.course.instructor)}</Typography>
				<Typography>Course: {reviewEntry?.course.title}</Typography>
				<Typography>Attempts: {reviewEntry?.submissions.length}</Typography>
			</Box>
			<Box padding={3}/>
			<ModuleTabs tabSelected={tabSelected} onChange={tab => setTab(tab)} className={classes.tabs} moduleTabs={[{label: "PORTFOLIO", value: "overview"}, {label: "MESSAGE LEARNER", value: "comments"}]}/>

			{tabSelected === "overview" && <>
				<Box sx={{display: "flex", gap: "15px", float: "right", marginBottom: "20px"}}>
					<Typography sx={{marginTop: "15px"}}>Submission:</Typography>
					<Select<number>
						value={submission}
						onChange={(e) => {
							setSubmission(e.target.value as number)
						}}
					>
						{reviewEntry?.submissions.map((e, i) => <MenuItem key={e.id} value={e.id}>Attempt {i + 1}: {new Date(e.submissionDate).toLocaleDateString()} {new Date(e.submissionDate).toLocaleTimeString()}</MenuItem>)}
					</Select>
				</Box>

				{selectedSubmission?.status && <PortfolioReviewStatusBanner status={selectedSubmission.status} />}
				{expiredSelected && <Box className={classes.expiredSubmissionInfoContainer}>
					<Typography>Please note: you are looking at an expired submission</Typography>
					<Button onClick={() => setSubmission(lastAttempt.id)}>View Latest Submission</Button>
				</Box>}

				<WYSIWYG className={classes.content}>
					{reviewEntry?.portfolio?.content ?? ""}
				</WYSIWYG>


				<Box padding={2}/>
				{selectedSubmission && <PortfolioQuestions auditable={user?.isAuditor} readOnly courseSlug={reviewEntry.course.slug} portfolioSlug={reviewEntry.portfolio.slug} onSubmit={() => {}} attempt={selectedSubmission} />}
			</>}
			{(tabSelected === "comments") && <CommentsSection courseSlug={courseSlug} moduleType={"portfolio" as any} moduleSlug={portfolioSlug}/>}

			<Box sx={{flexGrow: 5}}/>
			{(courseSlug && portfolioSlug && learnerId && reviewEntry?.portfolio.status === "awaiting-review" && !expiredSelected) && (
				<Box className={classes.feedbackActionContainer}>
					<Button color="error" onClick={() => setReview(courseSlug, portfolioSlug, parseInt(learnerId), "rejected")}>
						<CloseIcon />
						<Typography>Reject</Typography>
					</Button>
					<Button onClick={() => setReview(courseSlug, portfolioSlug, parseInt(learnerId), "approved")}>
						<DoneIcon />
						<Typography>Approve</Typography>
					</Button>
				</Box>
			)}
		</CenteredContainer>

	);
}