import {BoxProps} from "@mui/material";
import {FC} from "react";
import {PortfolioFilesUploadQuestion} from "./questions/PortfolioFilesUploadQuestion";
import {IPortfolioAnswer, IPortfolioQuestion, isPortfolioFileUploadQuestion} from "../../../../types/portfolio/IPortfolio";
import {IPortfolioAttempt} from "../../../../types/portfolio/IPortfolioAttempt";

interface IPropsCustom {
	question: IPortfolioQuestion,
	answer?: IPortfolioAnswer,
	attempt?: IPortfolioAttempt,
	onChange: (answer: IPortfolioAnswer) => void,
	onValidate?: (valid: boolean) => void,
	isError?: boolean,
	readOnly: boolean,
	auditable?: boolean
}

export type IProps = Omit<BoxProps, "onChange"> & IPropsCustom;

export const PortfolioQuestion: FC<IProps> = ({question, answer, onChange, readOnly, attempt, auditable, ...props}) => {
	const questionType = question.type;

	if (isPortfolioFileUploadQuestion(question))
		return <PortfolioFilesUploadQuestion auditable={auditable} attempt={attempt} question={question} onChange={onChange} answer={answer} readOnly={readOnly} {...props} />;

	throw new Error(`Question type (${questionType}) not supported in PortfolioQuestion component!`)
}
