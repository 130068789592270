import {Autocomplete, TextField} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useApi} from "@plumeuk/shapeshift-identity";
import {useEffect, useState} from "react";
import {ICourseEntity, IUserEntity} from "@plumeuk/shapeshift-types";

interface IProps {
	courseSlug?: string,
	onChange: (e?: string) => void
}

const useStyles = makeStyles()((theme) => ({
	courseAutoCompleteInput: {
		width: 250
	},
	courseInput: {
		padding: 0
	}
}));

export const CourseAutoCompleteInput: React.FC<IProps> = ({courseSlug, onChange}) => {
	const {classes} = useStyles();
	const [courseSearch, setCourseSearch] = useState<string>("");
	const [courses, searchCourses] = useApi<ICourseEntity[]>();

	useEffect(() => {
		searchCourses({url: "/api/user/searchCourses", data: {search: courseSearch}})
	}, [courseSearch])

	const options = (courses?.data ?? []).map(e => ({label: e.title, slug: e.slug}));

	return (

		<Autocomplete<{ slug: string, label: string }>
			value={options.find(e => e.slug === courseSlug)}
			getOptionLabel={e => {
				return e.label;
			}}
			isOptionEqualToValue={(e, v) => e?.slug === v?.slug}
			onChange={(_event, newValue) => {
				onChange(newValue?.slug ?? undefined);
			}}
			inputValue={courseSearch ?? ""}
			onInputChange={(_event, newInputValue) => {
				setCourseSearch(newInputValue);
			}}
			id="course-search"
			options={options}
			className={classes.courseAutoCompleteInput}
			renderInput={(params) => <TextField {...params} className={classes.courseInput} label="Course" />}
		/>

	);
}