import {FC} from "react";
import {makeStyles} from "tss-react/mui";
import {CenteredContainer} from "../../../../components/pageBase/centeredContainer";
import PortfolioQuestionsDataProvider from "./PortfolioQuestionsDataProvider";
import {PortfolioQuestionListContainer} from "./PortfolioQuestionListContainer";
import {IPortfolioAnswer} from "../../../../types/portfolio/IPortfolio";
import {IPortfolioAttempt} from "../../../../types/portfolio/IPortfolioAttempt";

const useStyles = makeStyles()((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		"& [ class*='answerCounter']": {
			display: "none"
		}
	}
}));

interface IProps {
	onSubmit?: (answers: IPortfolioAnswer[]) => void
	attempt: IPortfolioAttempt | undefined
	reviewCourseSlug?: string,
	courseSlug: string,
	reviewPortfolioSlug?: string,
	portfolioSlug: string,
	readOnly?: boolean,
	auditable?: boolean
}

export const PortfolioQuestions: FC<IProps> = ({onSubmit, attempt, courseSlug, readOnly, portfolioSlug, reviewCourseSlug, reviewPortfolioSlug, auditable}) => {
	const {classes} = useStyles();

	return (
		<CenteredContainer className={classes.centeredContainer}>
			<PortfolioQuestionsDataProvider courseSlug={reviewCourseSlug ?? courseSlug} portfolioSlug={reviewPortfolioSlug ?? portfolioSlug}>
				{({portfolioQuestions}) =>
					<PortfolioQuestionListContainer auditable={auditable} readOnly={readOnly} attempt={attempt} questions={portfolioQuestions} onSubmit={onSubmit} />
				}
			</PortfolioQuestionsDataProvider>
		</CenteredContainer>
	);
}