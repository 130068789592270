import {Box, BoxProps, Button, Typography} from "@mui/material";
import {FC, useCallback, useEffect, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {PortfolioQuestion} from "./PortfolioQuestion";
import {getFileTypeLabel, getPreviewUrl} from "./PortfolioQuesitonsFileDropzone";
import {IPortfolioAttempt} from "../../../../types/portfolio/IPortfolioAttempt";
import {IPortfolioAnswer, IPortfolioQuestion, isPortfolioFileUploadQuestion} from "../../../../types/portfolio/IPortfolio";

interface ICustomProps {
	questions?: IPortfolioQuestion[],
	onSubmit?: (answers: IPortfolioAnswer[]) => void
	attempt?: IPortfolioAttempt,
	readOnly?: boolean,
	auditable?: boolean
}

interface IStyleProps {
	isValid: boolean
}

const useStyles = makeStyles<IStyleProps>()((theme, {isValid}) => ({
	portfolioQuestionContainer: {
		textAlign: "left"
	},
	questionContainer: {},
	questionHeader: {
		display: "flex",
		alignItems: "center",
		marginTop: "55px",
		[theme.breakpoints.down("sm")]: {
			justifyContent: "space-between"
		}
	},
	questionCounter: {
		color: "grey",
		display: "inline-block",
		marginRight: "20px",
		whiteSpace: "nowrap"
	},
	answerCounter: {
		color: "white",
		backgroundColor: theme.palette.secondary.main,
		borderRadius: 100,
		padding: "5px 20px",
		fontSize: "12px",
		display: "inline-block"
	},
	buttonContainer: {
		width: "100%",
		textAlign: "right"
	},
	submitBtn: {
		marginTop: "20px",
		cursor: isValid ? "pointer" : "not-allowed"
	},
	errorMsg: {
		color: theme.palette.error.main,
		transition: "max-height 0.5s ease-out",
		overflow: "hidden"
	},
	errorContainer: {
		marginTop: "30px",
		height: "50px",
		width: "100%",
		textAlign: "center"
	}
}));

export type IProps = ICustomProps & Omit<BoxProps, "children" | "onSubmit">;

const urlToFile: (url: string, filename: string, mimeType: string) => Promise<File> = async (url, filename, mimeType) => {
	// Fetch the image data
	const response = await fetch(url);
	const blob = await response.blob();

	// Create a File object from the Blob
	return new File([blob], filename, {type: mimeType});
}

export const PortfolioQuestionListContainer: FC<IProps> = ({questions, onSubmit, readOnly, className, attempt, auditable, ...props}) => {
	const [answers, setAnswers] = useState<IPortfolioAnswer[]>(Array(questions?.length).fill(undefined));
	const [wasAnswerChanged, setWasAnswerChanged] = useState<boolean[]>(Array(questions?.length).fill(false))
	const [isValid, setIsValid] = useState(Array(questions?.length).fill(false));
	const [isError, setIsError] = useState(false);
	const {classes, cx} = useStyles({isValid: isValid.find(e => e)});
	const [attemptFiles, setLastAttemptFiles] = useState<File[][]>([])

	const isPortfolioValid = useCallback(() => isValid.filter(e => !e).length === 0, [isValid]);

	useEffect(() => {
		const getFiles: () => void = async () => {
			const answers = attempt?.answers ?? []
			const results: File[][] = []
			for (const answer of answers) {
				const data = await Promise.all(answer.files.map((f) => urlToFile(f.url, f.name, f.mime)))
				results.push(data)
			}
			setLastAttemptFiles(results)
		}
		getFiles()
	}, [attempt])

	useEffect(() => {
		setWasAnswerChanged(Array(questions?.length).fill(false))
		if (attemptFiles.length > 0) {
			setAnswers(attempt?.answers?.map((la, laIndex) => ({...la, files: la.files.map((f, fIndex) => ({name: f.name, file: attemptFiles[laIndex][fIndex]}))})) ?? Array(questions?.length).fill(undefined));
		} else {
			setAnswers(Array(questions?.length).fill(undefined));
		}
		setIsValid(Array(questions?.length).fill(false))
	}, [questions, attempt, attemptFiles]);

	useEffect(() => {
		setIsError(prev => {
			//we only attempt to reset error if already set
			if (prev === false)
				return prev;

			return isPortfolioValid()
		});
	}, [isPortfolioValid]);

	const handleChange = (answer: IPortfolioAnswer, i: number): void => {
		if (!questions) return;

		setAnswers(prev => {
			if (JSON.stringify(prev[i]) === JSON.stringify(answer)) {
				return prev;
			}
			prev[i] = {...prev[i], ...answer};
			return [...prev]
		});
		setWasAnswerChanged(prev => {
			const newChanged = [...prev]
			newChanged[i] = true
			return newChanged
		})
	}

	const handleValidateChange = (isValid: boolean, i: number): void => {
		setIsValid(prev => {
			if (prev[i] === isValid)
				return prev;

			prev[i] = isValid;
			return [...prev]
		});
	}

	const handleSubmit = (): void => {
		const valid = isPortfolioValid();
		setIsError(!valid);
		if (!valid) {
			return;
		}

		onSubmit?.(answers);
	}

	const _readOnly = readOnly || (attempt?.status === "approved" || attempt?.status === "awaiting-review")

	return (
		<Box className={cx(classes.portfolioQuestionContainer, className)} {...props}>
			{questions?.map((question, i) => {
				const attemptData = attempt?.answers?.[i];

				//This need to be updated to deal with other question types
				const lastAnswer: IPortfolioAnswer | null = (attemptData && attemptFiles.length > 0 && !wasAnswerChanged[i]) ? {
					questionId: attemptData.questionId,
					files: attemptData.files.map((f, j) => ({
						name: f.name,
						file: attemptFiles[i][j],
						id: f.id,
						preview: getPreviewUrl(attemptFiles[i][j]),
						type: getFileTypeLabel(f.mime ?? "")
					})),
					questionType: attemptData.questionType,
					audits: attemptData.audits
				} : null;

				return (
					<Box
						className={classes.questionContainer}
						key={`question_${i}`}
						data-test-id={`portfolio-list-question-${question.id}`}
					>
						<Box className={classes.questionHeader}>
							<Box className={classes.questionCounter}>Question {i + 1} of {questions?.length ?? "..."}</Box>
							<Box className={classes.answerCounter}>
								{question && isPortfolioFileUploadQuestion(question) && "1 answer"}
							</Box>
						</Box>
						<PortfolioQuestion
							attempt={attempt}
							auditable={auditable}
							onValidate={v => handleValidateChange(v, i)}
							isError={isError && !isValid[i]}
							onChange={e => handleChange(e, i)}
							answer={lastAnswer ?? answers[i]}
							question={question}
							readOnly={_readOnly}
						/>
					</Box>)
			})}

			<Box className={classes.errorContainer}>
				<Typography
					className={classes.errorMsg}
					sx={{maxHeight: isError ? "50px" : 0}}
				>
					{isValid.filter(e => !e).length} questions require attention before you can submit!
				</Typography>
			</Box>
			<Box className={classes.buttonContainer}>
				{(onSubmit && !_readOnly) && (
					<Button disabled={readOnly} data-test-id={"portfolio-question-list-container-submit-btn"} className={classes.submitBtn} onClick={handleSubmit}>
						Submit answers
					</Button>
				)}
			</Box>
		</Box>
	);
}